import { FaRegCheckCircle } from "react-icons/fa";
import { getPricingOptions } from "../../constants/index";
import Reveal from "../Animation/Reveal";
import { useTranslation } from "react-i18next";

const PriceSection = () => {
  const { t } = useTranslation();
  const pricingOptions = getPricingOptions(t);
  return (
    <div className="mt-20">
      <Reveal>
        <h2 className="my-8 text-3xl tracking-wide text-center sm:text-5xl lg:text-6xl">
          {t("priceTextone")}
          <span className="text-transparent bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text">
            {" "}
            {t("priceTexttwo")}
          </span>
        </h2>
      </Reveal>
      <div className="flex flex-wrap">
        {pricingOptions.map((option, index) => (
          <div key={index} className="w-full p-2 sm:w-1/2 lg:w-1/3">
            {" "}
            <Reveal>
              <div className="p-10 border border-neutral-700 rounded-xl">
                <p className="mb-8 text-4xl">
                  {option.title}
                  {option.title === "Social Media Managment"}
                </p>
                <p className="mb-8">
                  <span className="mt-6 mr-2 text-5xl">{option.price}</span>
                  {option.price === "od 500 zł" && (
                    <span className="tracking-tight text-neutral-400"></span>
                  )}
                </p>
                <ul>
                  {option.features.map((feature, index) => (
                    <li key={index} className="flex items-center mt-8">
                      <FaRegCheckCircle />
                      <span className="ml-2">{feature}</span>
                    </li>
                  ))}
                </ul>
                <a
                  href="/realizacja"
                  className="inline-flex items-center justify-center w-full h-12 p-5 mt-20 text-xl tracking-tight text-center transition duration-200 border border-orange-900 rounded-lg hover:bg-orange-900"
                >
                  {t("zoabaczText")}
                </a>
              </div>{" "}
            </Reveal>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceSection;
