import OfertaStrony from "../components/Oferta/OfertStrony";
import OfertaSocial from "../components/Oferta/OfertaSocial";
import OfertaGrafiki from "../components/Oferta/OfertaGrafiki";
import { Helmet } from "react-helmet-async";
import Oferta3D from "../components/Oferta/Oferta3D";

const Oferta = () => {
  return (
    <>
      <Helmet>
        <title>Oferta</title>
        <meta
          name="description"
          content="Nasza oferta - kompleksowe usługi projektowania stron, logo i grafik"
        />
        <link rel="canonical" href="/oferta" />
      </Helmet>
      <div>
        <OfertaStrony />
        <OfertaSocial />
        <OfertaGrafiki />
        <Oferta3D />
      </div>
    </>
  );
};
export default Oferta;
