import { getFeatures } from "../../constants/index";
import Reveal from "../Animation/Reveal";
import { useTranslation } from "react-i18next";

const Featuresection = () => {
  const { t } = useTranslation();
  const features = getFeatures(t);
  return (
    <div className="relative mt-20 border-b border-neutral-800 min-h-[800px]">
      <Reveal>
        <div className="text-center">
          <span className="h-6 px-2 py-1 text-sm font-medium text-orange-500 uppercase rounded-full bg-neutral-900">
            {t("mainBtnone")}
          </span>
          <h2 className="mt-10 text-3xl tracking-wide sm:text-5xl lg:text-6xl lg:mt-20">
            {t("mainTextOfertaone")}{" "}
            <span className="text-transparent bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text">
              {t("mainTextOfertatwo")}
            </span>
          </h2>
        </div>
      </Reveal>
      <div className="grid grid-cols-1 gap-4 px-4 mt-10 sm:grid-cols-2 lg:grid-cols-3 lg:mt-20">
        {features.map((feature, index) => (
          <Reveal>
            <div
              key={index}
              className="p-2 overflow-hidden  md:h-[300px] transition-transform duration-300 transform border border-orange-700 rounded-md hover:scale-105 "
            >
              {" "}
              <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto text-orange-700 rounded-full bg-neutral-900">
                {feature.icon}
              </div>
              <div>
                <h5 className="mt-4 mb-2 text-xl text-center">
                  {feature.text}
                </h5>
                <p className="p-2 mb-4 text-center text-md text-neutral-500">
                  {feature.description}
                </p>
              </div>
            </div>
          </Reveal>
        ))}
      </div>
    </div>
  );
};

export default Featuresection;
