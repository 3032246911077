import React, { useState } from "react";
import cer1 from "../../assets/storny/STRONA SKLEJONA CAR DETAILING.png";
import cer2 from "../../assets/storny/STRONA SKLEJONA CAR RENT.png";
import cer3 from "../../assets/storny/STRONA SKLEJONA TRENER.png";
import cer4 from "../../assets/storny/STRONA SKLEJONA hotel.png";
import Reveal from "../Animation/Reveal";
import { useTranslation } from "react-i18next";

const certificates = [
  {
    id: 1,
    title: "Firma Godna Zaufania",
    imageUrl: cer1,
  },
  {
    id: 2,
    title: "Złoty Laur Zaufania",
    imageUrl: cer2,
  },
  {
    id: 3,
    title: "Diament Biznesu",
    imageUrl: cer3,
  },
  {
    id: 4,
    title: "Złoty Laur Zaufania",
    imageUrl: cer4,
  },
];

const RealizacjaStrony = () => {
  const { t } = useTranslation();
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate);
  };

  const closeModal = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className="mt-12">
      <Reveal>
        <h2 className="mt-6 text-3xl tracking-wide  sm:text-5xl lg:text-6xl text-white bg-clip-text text-center">
          {t("stronyTitle")}
        </h2>
      </Reveal>
      <Reveal>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-4 mt-10">
          {certificates.map((certificate) => (
            <div
              key={certificate.id}
              className="cursor-pointer"
              onClick={() => handleCertificateClick(certificate)}
            >
              <img
                src={certificate.imageUrl}
                alt={certificate.title}
                className="object-contain	 w-full h-80 rounded-lg shadow-md hover:scale-105 transition-transform duration-300 	 "
              />
            </div>
          ))}
        </div>
      </Reveal>

      {selectedCertificate && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white text-2xl"
            >
              &times;
            </button>
            <img
              src={selectedCertificate.imageUrl}
              alt={selectedCertificate.title}
              className="object-contain max-w-screen-lg max-h-screen"
            />
            <p className="text-center text-white mt-4">
              {selectedCertificate.title}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealizacjaStrony;
